<template>
  <tr :class="rowClass">
    <table-cell :label="$t('invoice')">
      <v-progress-circular
        v-if="loading"
        :width="3"
        color="primary"
        indeterminate
      />
      <v-list-item v-if="obItemInvoice.id">
        <v-list-item-title>
          <span v-if="itemInvoiceType" v-text="itemInvoiceType.name" />
          <span
            class="ml-2 font-weight-bold"
            v-text="obItemInvoice.order_serial"
          />
          <span class="ml-2" v-text="obItemInvoice.order_number" />
        </v-list-item-title>
      </v-list-item>
    </table-cell>

    <table-cell :label="$t('invoice.currency')">
      <currency-preview :item="obItemInvoice.currency" />
    </table-cell>

    <table-cell :label="$t('invoice.balance')">
      <price-viewer :currency="obItemInvoice.currency" :value="fBalanceValue" />
    </table-cell>

    <table-cell :label="$t('amount')">
      <price-viewer
        v-if="signed"
        :currency="obItemInvoice.currency"
        :value="sTotalPrice"
      />
      <simple-unit-price-field
        v-else
        :disabled="signed || !validRow"
        :rules="priceRules"
        :value="sTotalPrice"
        update-on-blur
        @price:apply="onAddItem"
        @price:update="onSetPrice"
      />
    </table-cell>

    <table-cell :label="$t('actions')" class="text-lg-right">
      <item-actions
        v-if="canAdd || canDelete"
        :can-add="canAdd"
        :can-delete="canDelete"
        :disabled="signed || !validRow"
        hide-reset
        @item:add="onAddItem"
        @item:delete="onDeleteItem"
      />
    </table-cell>
  </tr>
</template>

<script lang="ts">
import {
  Invoice,
  InvoiceMovementType,
  InvoicePosition,
  InvoiceTypeCollection,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { InvoiceModule } from "@/store/invoice";
import { negative, positive } from "@/plugins/helpers";

import TableCell from "@/components/common/TableCell.vue";
import ItemActions from "@/modules/invoices/components/rows/ItemActions.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import SimpleUnitPriceField from "@/modules/invoices/components/SimpleUnitPriceField.vue";
import { number } from "mathjs";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import { EventBus } from "@/services/event-bus";

@Component({
  components: {
    CurrencyPreview,
    TableCell,
    ItemActions,
    PriceViewer,
    SimpleUnitPriceField,
  },
})
export default class DebtReceiptItemRow extends Vue {
  @VModel({ type: Object, default: () => new InvoicePosition() })
  item!: InvoicePosition;

  obItemInvoice: Invoice = new Invoice();
  obInvoiceTypeCollection: InvoiceTypeCollection = new InvoiceTypeCollection();
  obInvoiceFilters: Record<string, any> = {};
  loading = false;

  @Prop(Boolean) readonly canAdd!: boolean;
  @Prop(Boolean) readonly canDelete!: boolean;

  get itemInvoiceId(): number {
    return this.item.get("item_id", 0);
  }

  set itemInvoiceId(sValue: number) {
    this.item.set("item_id", sValue);
  }

  get itemInvoiceType(): Partial<InvoiceTypeData> {
    return this.obItemInvoice.get("invoice_type");
  }

  get bFiltersDone() {
    return !!this.obInvoiceFilters.invoice_type_id;
  }

  get fBalanceValue() {
    return this.obItemInvoice
      ? number(this.obItemInvoice.get("balance_value", 0))
      : 0;
  }

  get sTotalPrice() {
    return this.item.get("price", 0);
  }

  get sIndicator() {
    return this.item.get("indicator", 6);
  }

  set sIndicator(sValue: number) {
    const fPrice =
      sValue === 7 ? negative(this.sTotalPrice) : positive(this.sTotalPrice);

    if (fPrice !== this.sTotalPrice) {
      // this.obItemInvoice.total_price_value = fPrice;
      this.item.set("price", fPrice);
    }

    this.item.set("indicator", sValue);
  }

  get invoice() {
    return InvoiceModule.invoice;
  }

  get omit() {
    return this.$_.chain(InvoiceModule.positions)
      .map((obPosition) => obPosition.item_id)
      .filter((sItemId) => sItemId !== this.item.item_id)
      .value();
  }

  get signed() {
    return InvoiceModule.signed || InvoiceModule.isSigning;
  }

  get inView() {
    return InvoiceModule.inView;
  }

  get iMovementType(): number {
    return InvoiceModule.iMovementType;
  }

  get validRow() {
    return !!this.item.item_id && !!this.item.price;
  }

  get rowClass() {
    return {
      "invoice-table-row": true,
      "invoice-table-row--edit": this.canAdd,
      "v-data-table__mobile-table-row": this.isMobile,
    };
  }

  get priceRules() {
    if (this.signed) {
      return null;
    }

    const arRules = ["required"];

    if (this.fBalanceValue) {
      arRules.push("min_value:1", `max_value:${this.fBalanceValue}`);
    }

    return arRules.join("|");
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  async mounted() {
    // Preload invoice types to used un filters
    if (!this.obInvoiceTypeCollection.length) {
      this.loading = true;
      const obTypeFilter = { code: ["101", "102", "111", "112"] };
      await this.obInvoiceTypeCollection.filterBy(obTypeFilter).fetch();
      this.loading = false;
    }

    this.obItemInvoice = new Invoice();
    this.onSetFilters();
    await this.onLoadItemInvoice();
  }

  async onLoadItemInvoice() {
    if (this.itemInvoiceId && !this.obItemInvoice.id) {
      this.loading = true;
      this.obItemInvoice.set("id", this.itemInvoiceId);
      await this.obItemInvoice.fetch({
        params: { "filters[no_check_skip_balance]": 1 },
      });
      const fValue = this.sTotalPrice ?? this.fBalanceValue;
      this.onSetPrice(fValue);
      this.loading = false;
    }
  }

  onSetFilters() {
    const arInvoiceType: number[] = [];
    this.obInvoiceTypeCollection.each((obModel) => {
      if (["101", "111"].includes(obModel.code)) {
        arInvoiceType.push(obModel.id);
      }
    });

    let sCashCredit = this.invoice.is_cash ? "cash" : "credit";

    if (
      [
        InvoiceMovementType.CODE_DEBT,
        InvoiceMovementType.CODE_DEBT_RYA,
      ].includes(this.iMovementType)
    ) {
      sCashCredit = "credit";
    }

    this.$set(this.obInvoiceFilters, "invoice_type_id", arInvoiceType);
    this.$set(this.obInvoiceFilters, "signed", 1);
    this.$set(this.obInvoiceFilters, sCashCredit, 1);
    this.$set(this.obInvoiceFilters, "customer", this.invoice.customer_id);
  }

  onSetPrice(fValue: number) {
    if (this.signed || this.inView) {
      return;
    }

    // console.trace(`onSetPrice: ${fValue}`);
    this.item.set("price", fValue);
    // this.item.set("price_with_discounts", fValue);

    EventBus.emit("invoice.change.price", true);
  }

  onAddItem() {
    if (this.signed || !this.canAdd) {
      return;
    }

    this.$emit("add:empty");
  }

  onDeleteItem() {
    if (!this.canDelete) {
      return;
    }

    this.$emit("del", this.item);
  }

  onUpdateItem() {
    this.$emit("update", this.item);
  }
}
</script>
