<template>
  <sheet v-if="showItems" dense depressed light outlined>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template #body="data">
        <slide-y-up-transition group tag="tbody" :delay="300">
          <template v-for="(item, index) in data.items">
            <debt-receipt-item-row
              :key="`invoice-debt-receipt-row-${uid(index)}`"
              :can-add="false"
              :can-delete="data.items.length > 0"
              :value="item"
              @del="deleteItemPosition(index)"
              @update="updateItemPosition($event, index)"
            />
          </template>
        </slide-y-up-transition>
      </template>
    </v-data-table>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import { SlideYUpTransition } from "vue2-transitions";
import { InvoicePosition } from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import DebtReceiptItemRow from "@/modules/invoices/components/debt/DebtReceiptItemRow.vue";

@Component({
  components: { DebtReceiptItemRow, SlideYUpTransition },
})
export default class DebtReceiptItems extends Mixins(
  DataTableMixin,
  InvoiceMixin
) {
  @Prop(Boolean) readonly readOnly!: boolean;

  headers: DataTableHeader[] = [
    { text: "invoice", value: "invoice" },
    { text: "invoice.currency", value: "currency" },
    { text: "invoice.balance", value: "balance_value" },
    { text: "value", value: "total_price_with_tax" },
  ];
  obPosition: InvoicePosition = new InvoicePosition();

  get items(): InvoicePosition[] {
    return this.positions;
  }

  get canAdd(): boolean {
    return !this.readOnly && !this.isReceiptType;
  }

  get showItems(): boolean {
    return this.items.length > 0;
  }

  mounted() {
    this.addDTActionsHeader().mapDTHeaders();
    this.resetPositions();
  }

  created() {
    EventBus.on("invoice.customer.changed", this.resetInvoiceItems);
  }

  beforeDestroy() {
    EventBus.off("invoice.customer.changed", this.resetInvoiceItems);
  }

  resetInvoiceItems() {
    if (this.signed) {
      return;
    }

    this.resetPositions();
    this.resetReferences();
  }

  uid(iValue: number) {
    const obPosition = this.$_.get(this.items, iValue);
    return obPosition && obPosition.get("idx")
      ? obPosition.get("idx")
      : this.$_.uniqueId();
  }
}
</script>
